import React, { useState } from "react";

import { Route, HashRouter, BrowserRouter as Router, Switch } from "react-router-dom";

// import RetrieveChart from "./Components/RetrieveChart";
import PrivateRoute from "./Components/Auth/PrivateRoute";
import NodeAlarm from "./Components/NodeAlarm";
import Navbar from "./Components/Navbar";
import axiosInstance from "./shared/api";
import { RemoteMainLayout } from "./pages/Remote";

import Login from "../src/pages/Login/index";
import Dashboard from "../src/pages/Dashboard/index";
import NodeSummary from "./pages/NodeSummary";

import 'semantic-ui-css/semantic.min.css';
import axios from "axios";
import Register from "./pages/Register";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer} from 'react-toastify';
import Alarm from "./pages/Alarm/Alarm";
import BlockSettingDb from "./pages/Remote/BlockSettingDb";
import ManualOperation from "./pages/Remote/ManualOperation";
import SessionSetting from "./pages/Remote/SessionSetting";
import SettingIndex from "./pages/Remote/SettingIndex";
import Schedueler from "./Containers/Schedueler";
import RetrieveChart2 from "./Components/RetrieveChart2";
import Pressure from "./Containers/Pressure/Pressure";
import DashboardV3 from "./pages/DashboardV3";
import RetrieveChartAmchart5 from "./Components/RetrieveChartAmChart5";
import ImageEvent from "./pages/ImageEvent/ImageEvent";
import Summary from "./pages/Summary/Summary";

function App() {
	const [logo, setLogo] = useState('farmsens.png')

	return (
		<div>
			<Router>
        		<Switch>
				<Route path="/register" component={Register} exact/>
				
				<HashRouter>
					{localStorage.getItem("cloudatik") && <Navbar logo={logo}/>}
					<div style={{ paddingTop: 30 }}>
						<Route path="/" component={Login} exact />
						<PrivateRoute path="/image-event" auth={localStorage.getItem("cloudatik")} comp={ImageEvent} />
						<PrivateRoute
							path="/retrieve-chart"
							auth={localStorage.getItem("cloudatik")}
							comp={RetrieveChartAmchart5}
						/>
                        <PrivateRoute
							path="/retrieve-summary"
							auth={localStorage.getItem("cloudatik")}
							comp={Summary}
						/>
						<PrivateRoute
							path="/node-config"
							auth={localStorage.getItem("cloudatik")}
							comp={NodeAlarm}
						/>
						<PrivateRoute
							path="/remote/:device/:serial/:device_id/:telemetry/:page?"
							auth={localStorage.getItem("cloudatik")}
							comp={RemoteMainLayout}
						/>
						<PrivateRoute
							path="/remote-agrirrig-rad/block/:device/:device_id"
                            exact
							auth={localStorage.getItem("cloudatik")}
							comp={BlockSettingDb}
						/>
						<PrivateRoute
							path="/remote-agrirrig-rad/manual/:device/:device_id"
                            exact
							auth={localStorage.getItem("cloudatik")}
							comp={ManualOperation}
						/>
						<PrivateRoute
							path="/remote-agrirrig-rad/session/:device/:device_id"
                            exact
							auth={localStorage.getItem("cloudatik")}
							comp={SessionSetting}
						/>
						<PrivateRoute
							path="/schedueler/:device/:device_id"
                            exact
							auth={localStorage.getItem("cloudatik")}
							comp={Schedueler}
						/>
						<PrivateRoute
                        // "/remote/:device/:serial/:device_id/:telemetry/:page?"
							path="/iko-pressure/:device/:serial"
                            exact
							auth={localStorage.getItem("cloudatik")}
							comp={Pressure}
						/>
						<PrivateRoute
							path="/alarm/:device/:device_id"
                            exact
							auth={localStorage.getItem("cloudatik")}
							comp={Alarm}
						/>

						{/* v2 */}
						<PrivateRoute
							path="/v2/dashboard"
							auth={localStorage.getItem("cloudatik")}
							comp={DashboardV3}
						/>
						<PrivateRoute
							path="/v2/node"
							auth={localStorage.getItem("cloudatik")}
							comp={NodeSummary}
						/>
					</div>
				</HashRouter>
        		</Switch>
			</Router>
			<ToastContainer />
		</div>
	);
}

export default App;
